import algoliasearch from 'algoliasearch/lite';
import * as credentials from '../credentials';

export const defaultMenuType = 'menu-sub-hidden';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'id';
export const localeOptions = [{ id: 'id', name: 'Bahasa - LTR', direction: 'ltr' }];

export const firebaseConfig = {
  apiKey: 'AIzaSyD_ytaUfU73chG2wLf7WV3dLU3oRvY12kI',
  authDomain: 'api-5148455169863327468-319805.firebaseapp.com',
  databaseURL: 'https://api-5148455169863327468-319805.firebaseio.com',
  projectId: 'api-5148455169863327468-319805',
  storageBucket: 'api-5148455169863327468-319805.appspot.com',
  messagingSenderId: '827047270437',
  appId: '1:827047270437:web:7982e165bd74799a9f47cc',
  measurementId: 'G-JYHGL8FQK1',
};

export const isMultiColorActive = false;
export const defaultColor = 'light.red';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;

export const aCharCode = 65;
export const pageSizes = [8, 12, 20, 50, 100];

export const rmibAspects = [
  'outdoor',
  'mechanical',
  'computational',
  'scientific',
  'personal contact',
  'aesthetic',
  'literary',
  'musical',
  'social service',
  'clerical',
  'practical',
  'medical',
];

export const discAspects = ['dominance', 'influence', 'steadiness', 'compliance'];

export const accreditations = ['A', 'B', 'C', 'TT'];

export const collegeTypes = [
  'Akademi',
  'Politeknik',
  'Sekolah Kedinasan',
  'Sekolah Tinggi',
  'Universitas Swasta',
  'Universitas Negeri',
  'Institut',
  'Akademi Komunitas',
];

export const majorCategories = [
  'Budaya dan Bahasa',
  'Ekonomi dan Bisnis',
  'Kesehatan',
  'Komputer dan Teknologi',
  'MIPA',
  'Pendidikan',
  'Pertanian',
  'Profesi dan Ilmu Terapan',
  'Seni',
  'Sosial dan Humaniora',
  'Teknik',
];

export const hollandCodes = [
  'RIA',
  'RIS',
  'RIE',
  'RIC',
  'RAI',
  'RAS',
  'RAE',
  'RAC',
  'RSI',
  'RSA',
  'RSE',
  'RSC',
  'REI',
  'REA',
  'RES',
  'REC',
  'RCI',
  'RCA',
  'RCS',
  'RCE',
  'IRA',
  'IRS',
  'IRE',
  'IRC',
  'IAR',
  'IAS',
  'IAE',
  'IAC',
  'ISR',
  'ISA',
  'ISE',
  'ISC',
  'IER',
  'IEA',
  'IES',
  'IEC',
  'ICR',
  'ICA',
  'ICS',
  'ICE',
  'ARI',
  'ARS',
  'ARE',
  'ARC',
  'AIR',
  'AIS',
  'AIE',
  'AIC',
  'ASR',
  'ASI',
  'ASE',
  'ASC',
  'AER',
  'AEI',
  'AES',
  'AEC',
  'ACR',
  'ACI',
  'ACS',
  'ACE',
  'SRI',
  'SRA',
  'SRE',
  'SRC',
  'SIR',
  'SIA',
  'SIE',
  'SIC',
  'SAR',
  'SAI',
  'SAE',
  'SAC',
  'SER',
  'SEI',
  'SEA',
  'SEC',
  'SCR',
  'SCI',
  'SCA',
  'SCE',
  'ERI',
  'ERA',
  'ERS',
  'ERC',
  'EIR',
  'EIA',
  'EIS',
  'EIC',
  'EAR',
  'EAI',
  'EAS',
  'EAC',
  'ESR',
  'ESI',
  'ESA',
  'ESC',
  'ECR',
  'ECI',
  'ECA',
  'ECS',
  'CRI',
  'CRA',
  'CRS',
  'CRE',
  'CIR',
  'CIA',
  'CIS',
  'CIE',
  'CAR',
  'CAI',
  'CAS',
  'CAE',
  'CSR',
  'CSI',
  'CSA',
  'CSE',
  'CER',
  'CEI',
  'CEA',
  'CES',
];

const inclusiveness = require.context('../assets/inclusiveness', true, /\.svg$/);

export const careerIcons = inclusiveness.keys().map(path => ({ path, file: inclusiveness(path) }));

export const searchClient = algoliasearch(credentials.ALGOLIA_APP_ID, credentials.ALGOLIA_API_KEY);
